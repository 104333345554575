import {v4 as uuidv4} from 'uuid';

export const videoExtensions = [
    ".mov", // MOV
    ".mpg", ".mp2", ".mpeg", ".mpe", ".mpv", // MPEG-1
    ".m2v", // MPEG-2
    ".mp4", ".m4p", ".m4v", // MPEG4, MP4
    ".avi", // AVI
    ".wmv", // WMV
    ".flv", // FLV
    ".3gp", ".3gpp", // 3GPP
    ".webm", // WebM,
    ".mkv",
    ".divx",
    ".hevc",
    ".m2ts",
    ".mts",
    ".swf",
    ".ts",
    ".vob",
    ".xvid"
];

export const imageExtensions = [
    ".jpg", ".jpeg", ".png", ".tiff"
];

export const FILE_CHUNK_SIZE = 7000000;
export const MAX_FILE_SIZE_WITHOUT_MULTIPART = 50000000;

export const extensions = [...videoExtensions, ...imageExtensions];

function getMediaType(file) {
    const filenameParts = file.name.split('.');
    const extension = `.${filenameParts[filenameParts.length - 1]}`.toLowerCase();
    const foundVideoExtension = videoExtensions.find(ext => ext === extension);
    const foundImageExtension = imageExtensions.find(ext => ext === extension);

    if (foundImageExtension != null) {
        return 'image';
    } else if (foundVideoExtension != null) {
        return 'video';
    } else {
        return 'unknown';
    }
}

export function suggestIdentifiers(file) {
    const type = getMediaType(file)
    let suggestedExtId = uuidv4()
    let suggestedIndex = 0

    if (type === 'image' && window.lastFile != null && window.lastFile.type === 'image') {
        suggestedExtId = window.lastFile.suggestedExtId
        suggestedIndex = window.lastFile.suggestedIndex + 1
    }

    const details = {
        type,
        suggestedExtId,
        suggestedIndex
    }
    window.lastFile = details
    return details;
}

export function getFileSizeText(fileSize) {
    const kb = 1000;
    const mb = kb * 1000;
    const gb = mb * 1000;

    if (fileSize / kb < 1) {
        return "1 KB";
    } else if (fileSize / mb < 1) {
        const value = Math.ceil(fileSize / kb);
        return `${value} KB`;
    } else if (fileSize / gb < 1) {
        const value = Math.ceil(fileSize / mb);
        return `${value} MB`;
    } else {
        const value = Math.ceil(fileSize / gb);
        return `${value} GB`;
    }
}

export function compareFiles(one, two) {
    return one.name === two.name
        && one.size === two.size
        && one.lastModified === two.lastModified
}

export function getTotalSize(items) {
    let sum = 0;
    items.forEach(item => sum += item.file.size);

    return sum;
}